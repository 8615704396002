import './ColorTransition.css'

const ColorTransition = (props) => {


  return (
    <div className="transition" style={{backgroundImage: `linear-gradient(${props.startColor}, ${props.endColor})`}}>
    </div>
  );
};


export default ColorTransition;