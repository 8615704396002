import React from "react";
import "./BearCard.css"

import { Link } from 'react-router-dom'

const SELECTED_COLOR = '#a943ed'
const BASE_COLOR = 'grey'

class BearCard extends React.Component {

    constructor(props) {

        super(props)

        this.bear = props.bear;
        this.width = props.width || '250px';

        this.includeButton = props.includeButton || false;

        this.title = React.createRef();
        this.img = React.createRef();
        this.holder = React.createRef();

    }

    componentDidMount() {

        this.fillInfo();

    }

    componentDidUpdate() {

        this.fillInfo();

    }

    fillInfo() {

        if (this.dots !== undefined && this.selected !== undefined) {

            this.dots.forEach(dot => {
                dot.style.color = BASE_COLOR;
            });

            this.select(this.selected);

        }

        if (this.props.bear) {

            this.title.current.innerHTML = this.props.bear.name;

            this.img.current.src = this.props.bear.image;
            this.img.current.style.borderColor = this.props.bear.characteristics.background1Color + "9c" || 'lightgray';

            if (this.props.title) {

                this.title.current.style.fontSize = "60px";

            }

        }

    }

    navigate(destination) {

        return (evt) => {

            this.select(destination);

        }

    }

    scroll(dir, evt) {

        this.select(this.selected + dir);

    }

    select(num) {

        this.dots[this.selected].style.color = BASE_COLOR;

        this.selected = num;

        if (this.selected < 0) this.selected = this.dots.length - 1;
        else if (this.selected >= this.dots.length) this.selected = 0;

        this.dots[this.selected].style.color = SELECTED_COLOR;

        if (this.selected === 0) {
            this.img.current.src = this.props.bear.image;
        } else {
            this.img.current.src = this.props.bear.variants[this.selected - 1].image;
        }

        

    }
    
    render() {

        let button = '';

        if (this.includeButton) {
            button = <Link><div className="cardButton"></div></Link>
        }

        this.dots = undefined;
        this.selected = undefined;

        if (this.props.showVariants && this.props.bear) {

            const leftArrow = <div className="bearCardNav pointer noselect" onClick={this.scroll.bind(this, -1)}>&lt;</div>;
            const rightArrow = <div className="bearCardNav pointer noselect" onClick={this.scroll.bind(this, 1)}>&gt;</div>;

            const variantCounts = ( this.props.bear.variants !== null ? this.props.bear.variants.length : 0 ) + 1;

            this.dots = [];

            this.selected = 0;

            let dots = [];

            for (let i = 0; i < variantCounts; i++) {
                dots.push(<div key={i} className="dot bearCardNav pointer noselect" onClick={this.navigate(i)} ref={el => {if (el !== null) this.dots.push(el);}}>•</div>)
            }

            button = <div className="bearCardNavBar">
                {leftArrow}
                {dots}
                {rightArrow}
            </div>
        }

        return (
            <div ref={this.holder} className={this.props.className + " card"}>
                <div ref={this.title} className="cardTitle"></div>
                <img ref={this.img} className="pixelArt cardImage" alt={"A science bear!"}></img>
                {button}
            </div>
        );
    }

}

export default BearCard;