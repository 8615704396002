import './TopoOpt.css';

import MainHeader from 'modules/MainSubdomain/MainHeader';
import MainFooter from 'modules/MainSubdomain/MainFooter';

import topoOptGIF from "assets/images/toc.gif";

import TopologyRoadmap from 'components/TopologyRoadmap/TopologyRoadmap';
import SubdomainLink from 'components/Nav/SubdomainLink';

function TopoOpt() {

          /*
            <div className="fixedTitleBar">
                
                <div className="skinnyContent fixedTitleBarAnchor">
                    <div className="title fixedTitleBarMainText pixel">Topo-Opto-Crypto</div>
                </div>
                
                <div className="pageTitleText centerTextAlign centeredVertically flexResize" style={{flexBasis: '50%'}}>
                    Image of SIMP morph?
                </div>
            </div>
            */
    return (
        <div>
            <MainHeader isPixel={false} />

            <div className="skinnyContent titleBar imageBarBackground right">

                    <div className="aboveOverlay imageBarContent padded">
                        <div className="title ">Topo-Opto-Crypto</div>
                    </div>

            </div>
            
            <div className="skinnyContent gifRow centerText" >
                <div className="paragraph">
                    <div className="textContent">
                        Topo-Opto-Crypto is a collection of algorithmically generated artwork based on 
                        the mathematical methods of topology optimization.  
                    </div>
                    <SubdomainLink subdomain="topology" to='/' className="noLinkStyling">
                        <div className="topo button blue noselect pointer smallText">Explore now!</div>
                    </SubdomainLink>  
                </div>
                 
                
                <img src={topoOptGIF} className="centeredVertically topoOptGIF pixelArt" alt="Topo Opto NFT Example"></img>
            </div>

            <TopologyRoadmap/>
            
            <MainFooter isPixel={false}/>
            
        </div>
    );
}

//Topology optimization is used in a variety of engineering fields with the most popular methods 
//being broken down into four different types: (1) density-based methods, which include the popular 
//Solid Isotropic Material with Penalization (SIMP) technique, (2) hard-kill methods, including 
//Evolutionary Structural Optimization (ESO), (3) boundary variation methods (level set and phase field), 
//and (4) a new biologically inspired method based on cellular division rules.

export default TopoOpt;
