import React from "react";
import { Link } from 'react-router-dom'
import SubdomainLink from "./SubdomainLink";

class DropdownButton extends React.Component {

    constructor(props) {

        super(props)

        this.path =  props.path;
        this.text =  props.text;
        this.subdomain =  props.subdomain;
        this.color =  props.color;

        this.extraClasses =  props.className;

        this.isPixel =  props.isPixel || false;

        this.buttonHoverStart = this.buttonHoverStart.bind(this)
        this.buttonHoverEnd = this.buttonHoverEnd.bind(this)

    }

    render() {

      let classExtension = (this.isPixel ? ' pixel' : '') + ' ' + this.extraClasses;

      if (this.subdomain) {

        return (
          <SubdomainLink to={'/' + this.path} subdomain={this.subdomain} className="noLinkStyling">
  
            <div className={'dropdownButton' + classExtension}
                onMouseEnter={this.buttonHoverStart}
                onMouseLeave={this.buttonHoverEnd}>
                  {this.text}
            </div>
  
          </SubdomainLink>
        );
        
      }

      return (
        <Link to={'/' + this.path} className="noLinkStyling">

          <div className={'dropdownButton' + classExtension}
              onMouseEnter={this.buttonHoverStart}
              onMouseLeave={this.buttonHoverEnd}>
                {this.text}
          </div>

        </Link>
      );
    }

  
    buttonHoverStart(event) {

        event.target.style.backgroundColor = this.color

    }

    buttonHoverEnd(event) {

      event.target.style.backgroundColor = 'transparent'

    }
}

export default DropdownButton;