import React from "react";

import { Link } from 'react-router-dom'
import ColoredHover from "./ColoredHover";

class TextLink extends ColoredHover {

    constructor(props) {

        super(props)

        this.path =  props.path;
        this.children =  props.children;
        this.inFooter  = props.inFooter || false;

        this.isPixel =  props.isPixel || false;
        
    }

    render() {

      let classExtension = (this.inFooter ? ' small' : '') + (this.isPixel ? ' pixel' : '');

      return (
          <Link to={'/' + this.path} className={"noLinkStyling linkHolder" + classExtension}>

            <div className={'linkText pointer' + classExtension}
                onMouseEnter={this.buttonHoverStart}
                onMouseLeave={this.buttonHoverEnd}>
                  {this.children}
            </div>

          </Link>
        );
    }
}

export default TextLink;