import React from "react";
import * as d3 from "d3";

import tempBearImage from 'assets/images/Gal-bearileo.png'
import tempBearImage2 from 'assets/images/Gal-bearileo-1.png'

import Bear from 'classes/Bear'

import BearInfoModal from "./BearInfoModal";

const radius = 60;

class BearNode {

    constructor(id, image, year, children) {

        this.id = id;
        this.image = image;
        this.year = year;

        this.children = children;

        this.bear = new Bear(this.id, this.image, {background1Color: "#07254a", wikiTitle: "Galileo_Galilei"}, false, [new Bear(this.id, tempBearImage2, {background1Color: "#07254a", wikiTitle: "Galileo_Galilei"}, true)])

    } 

}

class Tree extends React.Component {

    constructor(props) {

        super(props)

        this.canvas = null;

        this.bears = {
            'Galileo' : new BearNode('Galileo', tempBearImage, 1000, ['Test1', 'Test2', 'Test3',]),
            'Test1': new BearNode('Test1', tempBearImage, 1050, ['Test17', 'Test7',]),
            'Test2': new BearNode('Test2', tempBearImage2, 1050, ['Test17', 'Test18', 'Test6',]),
            'Test3': new BearNode('Test3', tempBearImage, 1050, ['Test18',]),
            'Test17': new BearNode('Test17', tempBearImage, 1100, ['Test4', 'Test5',]),
            'Test18': new BearNode('Test18', tempBearImage, 1120, ['Test5',]),
            'Test4': new BearNode('Test4', tempBearImage, 1350, []),
            'Test5': new BearNode('Test5', tempBearImage2, 1400, []),
            'Test6': new BearNode('Test6', tempBearImage2, 1550, ['Test8',]),
            'Test7': new BearNode('Test7', tempBearImage, 1500, ['Test8', 'Test9',]),
            'Test8': new BearNode('Test8', tempBearImage, 1650, ['Test10', 'Test11', 'Test12',]),
            'Test9': new BearNode('Test9', tempBearImage, 1650, []),
            'Test10': new BearNode('Test10', tempBearImage, 1750, ['Test11', 'Test14', 'Test20']),
            'Test11': new BearNode('Test11', tempBearImage2, 1760, ['Test15', 'Test16', ]),
            'Test12': new BearNode('Test12', tempBearImage, 1780, ['Test21', ]),
            'Test13': new BearNode('Test13', tempBearImage, 1850, ['Test20', 'Test15',]),
            'Test14': new BearNode('Test14', tempBearImage2, 1850, []),
            'Test15': new BearNode('Test15', tempBearImage2, 1870, []),
            'Test16': new BearNode('Test16', tempBearImage, 1880, []),
            'Test19': new BearNode('Test19', tempBearImage, 1950, []),
            'Test20': new BearNode('Test20', tempBearImage2, 1950, []),
            'Test21': new BearNode('Test21', tempBearImage, 1976, [])
        };

        this.orderedBears = Object.values(this.bears).sort((first, second) => {return first.year - second.year});

        this.connections = [];

        for (let i = 0 ; i < this.orderedBears.length; i++) {

            const bear = this.orderedBears[i];

            for (let j = 0; j < bear.children.length; j++) {

                this.connections.push( {source: bear.id, target: bear.children[j], value: 1} );

            }

        }

        this.treeEl = React.createRef();
        this.modal = React.createRef();

        this.state = { selectedBear : null };

        this.resize = this.resize.bind(this);

    }

    drag(simulation) {
  
        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.01).restart();
            d.fx = d.x;
            d.fy = d.y;
        }
        
        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }
        
        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }
        
        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    }

    hover(selection) {

        const self = this;

        const line = d3.line().context(null);

        const whiteRadius = 10
        const textWidth = 48
        const color = "black"

        function hoverStart(event, d) {
            let hovered = d3.select(this)
            
            const yearLine = self.transform.insert("g", ":first-child")
                .attr("transform", `translate(${d.x} ${d.y})`)
                .attr("class", "yearLine")
            
            yearLine.append("path")
                .attr("d", line([[-10000, 0], [10000, 0]]))
                .attr("stroke", color)

            hovered.insert("circle", ":first-child")
                .attr("class", "yearLine")
                .attr("fill", "white")
                .attr("r", radius + 10)
                
            hovered.append("rect")
                .attr("class", "yearLine")
                .attr("fill", "white")
                .attr("width", textWidth + 5)
                .attr("height", 16)
                .attr("x", radius + 5)
                .attr("y", -8)

            hovered.append('text')
                .attr('class', 'yearLine textContent pixel')
                .attr('text-anchor', 'left')
                .attr("x", radius + 5)
                .attr("y", 5)
                .text(d.year)
                .attr("stroke", color)
                .attr("stroke-width", "0")
                .style("font-size", "28px")

        }

        function hoverEnd(event, d) {
            d3.selectAll(".yearLine").remove()
        }

        selection
            .on("mouseover", hoverStart)
            .on("mouseout", hoverEnd);
    }

    zoom() {

        function handleZoom(event) {

            this.transform.attr('transform', event.transform)
    
        }

        return d3.zoom().on('zoom', handleZoom.bind(this));

    }

    click(selection) {

        const self = this;

        function clicked(event, d) {
            
            if (event.defaultPrevented) return; // dragged
        
            self.setState((state) => {
                let newState = {...state};
                newState.selectedBear = d.bear;
                return newState;
            });

            self.modal.current.openModal();

        }

        selection.on("click", clicked);
    }

    componentDidMount() {

        this.svgCreator();

        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {

    }

    
    componentDidUpdate() {

        window.removeEventListener('resize', this.resize);

    }

    resize(evt) {
        if (this.treeEl.current) this.treeEl.current.style.width = document.body.clientWidth + "px";
        if (this.svg) this.svg.attr("viewBox", [0, 0, document.body.clientWidth, 1000]);
    }

    svgCreator() {

        const container = document.getElementById("tree");

        const containerRect = container.getBoundingClientRect();
        const height = containerRect.height;
        const width = containerRect.width;

        const links = this.connections;
        const nodes = this.orderedBears;
      
        const simulation = d3.forceSimulation(nodes)
            .force("link", d3.forceLink(links).id(d => d.id))
            .force("charge", d3.forceManyBody())
            .force("center", d3.forceCenter(width / 2, height / 2))
            .force("collide", d3.forceCollide(radius * 2));
      
        const svg = this.svg = d3
            .select(container)
            .append("svg")
            .attr("viewBox", [0, 0, width, height])

        const transform = this.transform = svg.append("g")

        svg.call(this.zoom())

        const defs = svg.append('defs')

        defs.append('clipPath')
            .attr('id', 'circle')
            .append('circle')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', radius)
      
        const link = transform.append("g")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(links)
            .join("line")
                .attr("stroke-width", d => Math.sqrt(d.value));
      
        const node = this.node = transform.append("g")
            .attr("stroke", "#fff")
            .attr("stroke-width", 1.5)
            .selectAll("g")
            .data(nodes)
            .join("g")
                .attr("r", 100)
                .call(this.drag(simulation));

        this.click(node);

        this.hover(node);

        const images = node.append("svg:image")
            .attr("xlink:href",  function(d) { return d.image;})
            .attr("x", function(d) { return -radius;})
            .attr("y", function(d) { return -radius;})
            .attr("height", 2 * radius)
            .attr("width", 2 * radius)
            .attr('clip-path', 'url(#circle)')
            .attr('class', 'pixelArt');
      
        node.append("title")
            .text(d => d.id);
      
        simulation.on("tick", () => {
            node
                .attr("transform", d => {
                    d.y = 2 * (d.year - 1000) + 100;
                    d.vy = 0;

                    return `translate(${d.x} ${d.y})`;
                })

                
            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);
      
        });
    }



    render() {

        return (
            <div>
                <div ref={this.treeEl} id="tree" style={{ width : document.body.clientWidth, height: '1000px' }} />
                <BearInfoModal ref={this.modal} bear={this.state.selectedBear}/>
            </div>
        );

    }


}

export default Tree;