import React from "react";

class SubdomainLink extends React.Component {

    render() {

        let subdomain = '';

        if (this.props.subdomain !== undefined) {
            subdomain = this.props.subdomain + '.';
        }

        //const link = window.location.protocol + "//" + subdomain + window.location.host.split('.').pop() + this.props.to;
        const link = window.location.protocol + "//" + subdomain + "thebearlab.com" + this.props.to;

        return (
            <a href={link} className={this.props.className}>

                {this.props.children}

            </a>
        );
    }

}


export default SubdomainLink;