import React from "react";
import "./ImageScroll.css"

const SCROLL_TIME = 2000;

class ImageScroll extends React.Component {

    constructor(props) {

        super(props)

        this.title = React.createRef();
        this.img = React.createRef();
        this.holder = React.createRef();

        this.selected = 0;

        this.scrollRight = this.scroll.bind(this, 1);

        this.timer = setTimeout(this.scrollRight, SCROLL_TIME);

    }

    componentDidMount() {

        this.fillInfo();

    }

    componentDidUpdate() {

        this.fillInfo();

    }

    fillInfo() {

        this.select(this.selected);

    }

    navigate(destination) {

        return (evt) => {

            this.select(destination);

        }

    }

    scroll(dir, evt) {

        this.select(this.selected + dir);

        clearTimeout(this.timer);

        this.timer = setTimeout(this.scrollRight, SCROLL_TIME);

    }

    select(num) {

        this.selected = num;

        if (this.selected < 0) this.selected = this.props.images.length - 1;
        else if (this.selected >= this.props.images.length) this.selected = 0;

        if (this.img.current) this.img.current.src = this.props.images[this.selected].image; 
        if (this.title.current) this.title.current.innerHTML = this.props.images[this.selected].title; 

    }
    
    render() {

        return (
            <div ref={this.holder} className={this.props.className + " card"}>
                <img ref={this.img} className="pixelArt cardImage" alt={"A Topology Optimization NFT!"}></img>
                <div ref={this.title} className="cardTitle centeredHorizontally"></div>
            </div>
        );
    }

}
/*
                    <div className="centeredVertically">
                        <div className="bearCardNav pointer noselect arrow" onClick={this.scroll.bind(this, -1)}>&lt;</div>
                    </div>
                    <img ref={this.img} className="pixelArt cardImage" alt={"A Topology Optimization NFT!"}></img>
                    <div className="centeredVertically">
                        <div className="bearCardNav pointer noselect arrow" onClick={this.scroll.bind(this, 1)}>&gt;</div>
                    </div>
*/

export default ImageScroll;