import React from "react";
import DropdownButton from "./DropdownButton";

import "./Dropdown.css"

class Dropdown extends React.Component {

    constructor(props) {

        super(props)

        this.dropdownStarted = false;

        this.id =  props.id;
        this.text =  props.text;
        this.dropdowns =  props.dropdowns;
        this.mobileDropdowns =  props.mobileDropdowns;

        this.isPixel =  props.isPixel || false;

        this.includeArrow = props.includeArrow || true;

        this.color = props.color || "black";
        this.hoverColor = props.hoverColor || "black";
        this.backgroundColor = props.backgroundColor || "black";

        let i = 0;
        this.dropdowns = this.dropdowns.map(dropdown => {
            const {text, path} = dropdown;
            return <DropdownButton key={i++} text={text} path={path} subdomain={dropdown.subdomain} color={this.hoverColor} isPixel={this.isPixel}/>
        });

        this.mobileDropdowns = this.mobileDropdowns.map(dropdown => {
            const {text, path} = dropdown;
            return <DropdownButton key={i++} text={text} path={path} subdomain={dropdown.subdomain} color={this.hoverColor} isPixel={this.isPixel} className="mobileOnly"/>
        });


        this.resize = this.resize.bind(this)
        this.toggleDropdown = this.toggleDropdown.bind(this)
        this.startDropdown = this.startDropdown.bind(this)
        this.endDropdown = this.endDropdown.bind(this)

    }

    componentDidMount() {
        this.dropdownContent = document.getElementById('dropdownContent'+this.id)
        this.dropdown = document.getElementById('dropdown'+this.id)

        this.resize()

        this.dropdownContent.style.display = 'none'
        window.addEventListener('resize', this.resize)
    }


    resize() {
        this.dropdownContent.style.display = 'flex'

        this.dropdownContent.style.display = 'none'
    }

    
    render() {

        let classExtension = this.isPixel ? ' pixel' : '';

        return (
            <div className="noLinkStyling linkHolder mobile flexColumn popUp"
                 id={'dropdownHolder'+this.id}
                 onMouseEnter={this.startDropdown}
                 onMouseLeave={this.endDropdown}>

                <div className={"dropdown linkText pointer" + classExtension}
                    id={'dropdown'+this.id}
                    onTouchStart={this.toggleDropdown}>
                        
                    <div className="dropdownTitle">{this.props.children}</div>

                    {this.includeArrow && <div className="dropdownArrow">▼</div>}

                    <div className="mobileDropdown">☰</div>
                    
                </div>
                
                <div className="dropdownContentAnchor">
                    <div className="dropdownContent"
                        style={{backgroundColor: this.backgroundColor}}
                        id={'dropdownContent'+this.id}>
                        {this.mobileDropdowns}
                        {this.dropdowns}
                    </div>
                </div>
                

            </div>
        );
    }

    toggleDropdown(event) {

        if (!this.dropdownStarted) this.startDropdown(event);
        else this.endDropdown(event);

    }
    
    startDropdown(event) {

        document.getElementById('dropdownContent'+this.id).style.display = "flex";
    
        this.dropdown.style.color = this.color;

        this.dropdownStarted = true;
    }

    endDropdown(event) {

        document.getElementById('dropdownContent'+this.id).style.display = "none";
    
        this.dropdown.style.color = 'black';

        this.dropdownStarted = false;
    
    }


}

export default Dropdown;