import React from "react";

import './Header.css'

//import beaker from 'assets/images/PurpleBeaker.png'

import { Link } from 'react-router-dom'
import SubdomainLink from "components/Nav/SubdomainLink";

const Header = (props) => {

  const isPixel = props.isPixel || false;

  const isSubdomain = props.isSubdomain || false;

  const subdomainText = props.subdomainText || '';

  let link = (
    <Link to='/' className="flexRow noLinkStyling headerLeft">
      <div className={'headerTitle centeredVertically' + (isPixel ? ' pixel' : '')}>Bear Science</div>
    </Link>
  );

  if (isSubdomain) {

    /*<img src={beaker} className="headerTitleImage centeredVertically pixelArt" alt=""></img>*/

    link = (
      <SubdomainLink to='/' className="flexRow noLinkStyling headerLeft">
        <div className={'flexColumn centeredVertically'}>
          <div className={'headerTitle' + (isPixel ? ' pixel' : '')}>Bear Science</div>
          <div className={'headerSubtitle' + (isPixel ? ' pixel' : '')}>{subdomainText}</div>
        </div>
      </SubdomainLink>
    );

  }

  return (
    <div>
      <div className="header">
        <div className={'headerFooterContent' + (isPixel ? ' pixel' : '')}>
          <div className={'headerContent' + (isPixel ? ' pixel' : '')}>

            {link}
            
            {props.children}

          </div>
        </div>
      </div>
      <div className="headerSpace"/>

    </div>
  );
};

/*
<Dropdown id={i++} isPixel={isPixel} text="Stuff" dropdowns={ [ { text: 'Login Page', path: 'about' }, { text: 'Other', path: 'other' }, ] }/>
<Dropdown id={i++} isPixel={isPixel} text="My Stuff" dropdowns={ [ { text: 'About', path: 'about' }, { text: 'Other', path: 'other' }, ] }/>
<Dropdown id={i++} isPixel={isPixel} text="Our Stuff" dropdowns={ [ { text: 'About', path: 'about' }, { text: 'Other', path: 'other' }, ] }/>
<Dropdown id={i++} isPixel={isPixel} text="Your Stuff" dropdowns={ [ { text: 'Login Page', path: 'about' }, { text: 'Other', path: 'other' }, ] }/>
*/

export default Header;