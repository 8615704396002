import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { BrowserRouter as Router } from 'react-router-dom'

import SubdomainRouter from "./subdomains/SubdomainRouter.js"

ReactDOM.render((

    <Router>
        <SubdomainRouter/>
    </Router>


), document.getElementById('root'))

