import './TopologyHome.css';

import TopologyHeader from '../TopologyHeader';
import TopologyFooter from '../TopologyFooter';

import TopologyCandyMachineWrapper from "modules/TopologySubdomain/CandyMachine/CandyMachineWrapper"
import TopologyRoadmap from 'components/TopologyRoadmap/TopologyRoadmap';

import ColorTransition from 'components/ColorTransition/ColorTransition'

//import topoOptGIF from "assets/images/toc.gif";
import topoImage from 'assets/images/TopoAnimation_rev.gif';
import SubdomainLink from 'components/Nav/SubdomainLink';

function TopologyHome() {

    return (
        <div>
            <div className="block" id="title">
                <TopologyHeader isPixel={false} />

                <div className="titleBar flexResize wideContent right darkOverlay skinnyContent pixelArt bar whiteText barBackground right wideBackgroundImage"
                     style={{backgroundImage: `url(${topoImage})`, 
                            backgroundColor: '#000000'}}>

                     <div className="aboveOverlay imageBarContent padded">
                        <div className="title ">Topo-Opto-Crypto</div>
                     </div>

                </div>
            </div>
            
            <div className="block" id="info">
                <div className="skinnyContent centerText" >
                    <div>
                        <div className="topo paragraph centeredHorizontally">
                            <div className="textContent">
                                Topo-Opto-Crypto is a collection of algorithmically generated artwork based on 
                                the mathematical methods of topology optimization. Topology optimization is 
                                the process of determining the optimal material layout within a design 
                                domain given a defined set of loads and constraints. The goal of the 
                                optimization is problem dependent, though a typical objective 
                                is often reducing weight while maintaining form and function of an engineered 
                                part. Topology optimization is used in a variety of engineering fields with 
                                the multiple methods to choose from. Our first drop of Topo-Crypto-Opt here is 
                                based of the Solid Isotropic Material with Penalization (SIMP) technique. Check
                                out <SubdomainLink subdomain="blog" to="">our blog</SubdomainLink> (coming soon) for details outlining the math behind the method.  
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <ColorTransition startColor="white" endColor="#eee" />
            
            <div className="block" style={{backgroundColor: "#eee"}} id="mint">
                <TopologyCandyMachineWrapper/>
            </div>

            <ColorTransition startColor="#eee" endColor="white"/>

            <div className="block" id="roadmap">
                <TopologyRoadmap/>
            </div>

            <TopologyFooter isPixel={false}/>
            
        </div>
    );
}

//Topology optimization is used in a variety of engineering fields with the most popular methods 
//being broken down into four different types: (1) density-based methods, which include the popular 
//Solid Isotropic Material with Penalization (SIMP) technique, (2) hard-kill methods, including 
//Evolutionary Structural Optimization (ESO), (3) boundary variation methods (level set and phase field), 
//and (4) a new biologically inspired method based on cellular division rules.

 //                   <div className="topo paragraph">
 //                       <div className="textContent">   
 //                           Topology optimization is used in a variety of engineering fields with the most popular methods 
  //                          being broken down into four different types: (1) density-based methods, which include the popular 
  //                          Solid Isotropic Material with Penalization (SIMP) technique, (2) hard-kill methods, including 
  //                          Evolutionary Structural Optimization (ESO), (3) boundary variation methods (level set and phase field), 
 //                           and (4) a new biologically inspired method based on cellular division rules.
 //                       </div>
 //                   </div>
export default TopologyHome;
