import LinkBar from 'components/Nav/LinkBar';
import Header from 'components/Header/Header';

import ScrollLink from 'components/Nav/ScrollLink';

import {COLORS} from 'components/Nav/LinkColors'

function TopologyHeader(props) {
    return (
        <Header isPixel={props.isPixel} isSubdomain={true} subdomainText="Topo-Opto-Crypto">
            <LinkBar isPixel={props.isPixel}>
                <ScrollLink hoverColor={COLORS[0]} scrollElementID="title" isPixel={props.isPixel} inFooter={false}>Home</ScrollLink>
                <ScrollLink hoverColor={COLORS[1]} scrollElementID="info" isPixel={props.isPixel} inFooter={false}>Description</ScrollLink>
                <ScrollLink hoverColor={COLORS[2]} scrollElementID="mint" isPixel={props.isPixel} inFooter={false}>Mint</ScrollLink>
                <ScrollLink hoverColor={COLORS[3]} scrollElementID="roadmap" isPixel={props.isPixel} inFooter={false}>Roadmap</ScrollLink>
            </LinkBar>
        </Header>
    );
}


export default TopologyHeader;
