import React from "react";
import './Footer.css'

class Footer extends React.Component {
  
  constructor(props) {

    super(props)

    this.isPixel = props.isPixel || false;

    this.resize = this.resize.bind(this);

  }

  componentDidMount() {

    this.spaceFillerDiv = document.getElementById('extraSpace')

    this.resize()

    window.addEventListener('resize', this.resize)
    window.addEventListener('load', this.resize)

    const resizeObserver = new ResizeObserver(this.resize);
    resizeObserver.observe(document.body);
    

  }


  resize() {

    //Resize content
    this.spaceFillerDiv.style.height = 0;
    this.spaceFillerDiv.style.height = (window.innerHeight - document.body.clientHeight) + 'px';

  }

  render() {

    return (
      <div>
        <div id="extraSpace"/>
        <div className='headerFooterContent footerBar flexRow'>

          <div style={{flexGrow: 1}}/>
          
          {this.props.children}

        </div>
      </div>
    );

  }

};

export default Footer;