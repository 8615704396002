import React from "react";
import ColoredHover from "./ColoredHover";

class SocialMediaLink extends ColoredHover {

    constructor(props) {

        super(props)

        this.img =  props.img;
        this.link =  props.link;
        
        this.inFooter  = props.inFooter || false;
        this.isPixel =  props.isPixel || false;

    }

    render() {

      let classExtension = (this.inFooter ? ' small' : '');
      let logoMask = `url(${this.img})`

      return (
        <a href={this.link} className={"noLinkStyling linkHolder forLogo" + classExtension}>

          <div className={"logo centeredVertically pointer" + classExtension} 
               style={{ WebkitMaskImage: logoMask, maskImage: logoMask }} 
               onMouseEnter={this.buttonHoverStart}
               onMouseLeave={this.buttonHoverEnd}
               alt="Social Media Logo"/>

        </a>
      );
    }

}


export default SocialMediaLink;