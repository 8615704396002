import React from "react";

class ColoredHover extends React.Component {

    constructor(props) {

        super(props)

        this.hoverColor = props.hoverColor || undefined;
        this.hoverBackgroundColor = props.hoverBackgroundColor || undefined;
        this.hoverOpacity = props.hoverOpacity || undefined;

        this.buttonHoverStart = this.buttonHoverStart.bind(this)
        this.buttonHoverEnd = this.buttonHoverEnd.bind(this)
        
    }

  
    buttonHoverStart(event) {

        if (this.hoverColor) event.target.style.color = this.hoverColor;
        if (this.hoverBackgroundColor) event.target.style.backgroundColor = this.hoverBackgroundColor;
        if (this.hoverOpacity) event.target.style.opacity = this.hoverOpacity;

    }

    buttonHoverEnd(event) {

      if (this.hoverColor) event.target.style.color = 'black';
      if (this.hoverBackgroundColor) event.target.style.backgroundColor = 'black';
      if (this.hoverOpacity) event.target.style.opacity = 1;

    }
}

export default ColoredHover;