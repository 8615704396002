import './Home.css';

import galileoImage from 'assets/images/Gal-bearileo-expanded.png';
import topoImage from 'assets/images/TopoExample.png';

import MainHeader from 'modules/MainSubdomain/MainHeader';
import MainFooter from 'modules/MainSubdomain/MainFooter';

import { Link } from 'react-router-dom';
import SubdomainLink from 'components/Nav/SubdomainLink';

function Home() {


    return (
        <div>
            <div id="dropdownContentHolder"></div>
            <MainHeader isPixel={false} />

            <div className="skinnyContent pageTitleBar" >
                <div className="pageTitle flexResize centeredVertically" 
                     style={{flexBasis: 'auto'}}>Welcome to the <br/> Lab-BEAR-tory</div>
                <div className="line" />
                <div className="pageTitleText centerTextAlign centeredVertically flexResize" 
                     style={{flexBasis: '50%'}}>
                    Hello and welcome! Our goal is here at Bear Science is to teach people about a number 
                    of different science and engineering topics while also learning something ourselves. 
                    Just recently, we launched our first NFT project making art out of science. Learn more below...
                </div>
            </div>
            
            <div className="darkOverlay skinnyContent pixelArt bar whiteText barBackground right projectBar wideBackgroundImage"
                 style={{backgroundImage: `url(${topoImage})`, 
                         backgroundColor: '#000000'}}>
            
                <div className="aboveOverlay barContent left">
                    <div className="title2 padded">Topo-Opto-Crypto</div>
                    <div className="textContent padded" >
                        Algorithmically generated artwork based on the 
                        mathematical methods of topology optimization.
                    </div>
                    <SubdomainLink subdomain="topology" to='/' className="noLinkStyling">
                        <div className="button white noselect pointer smallText whiteText">Explore now!</div>
                    </SubdomainLink>  
                </div>
            </div>
            
            <MainFooter isPixel={false}/>
            
        </div>
    );
}

export default Home;
