import { Switch, Route } from "react-router";
import BlogComingSoon from "./BlogComingSoon";

function BlogSubdomain() {
    return (
        <Switch>
          <Route path="/" component={BlogComingSoon}/>
        </Switch>
    );
};

export default BlogSubdomain;