import { Switch, Route } from "react-router";

import Home from 'modules/MainSubdomain/Home/Home'
import About from 'modules/MainSubdomain/About/About'
import BearScientists from 'modules/MainSubdomain/BearScientists/BearScientists'
import TopoOpt from 'modules/MainSubdomain/TopoOpt/TopoOpt'

function DefaultSubdomain() {
    return (
        <Switch>
          <Route path="/about" component={About}/>
          
          <Route path="/topo-opto-crypto" component={TopoOpt}/>
          <Route path="/" component={Home}/>
        </Switch>
    );
};

/*
<Route path="/scientist" component={BearScientists}/>
*/

export default DefaultSubdomain;