import LinkBar from 'components/Nav/LinkBar';
import Footer from 'components/Footer/Footer';
import {COLORS} from 'components/Nav/LinkColors'
import TextLink from 'components/Nav/TextLink';

function BlogFooter(props) {

    const isPixel = props.isPixel || false;

    return (
        <Footer isPixel={isPixel} >
            <LinkBar isPixel={isPixel} inFooter={true}>
                <TextLink hoverColor={COLORS[0]} path="" isPixel={props.isPixel} inFooter={true}>Home</TextLink>
            </LinkBar>
        </Footer>
    );
}


export default BlogFooter;
