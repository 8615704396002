import ColoredHover from "./ColoredHover";

class ScrollLink extends ColoredHover {

    constructor(props) {

        super(props)

        this.scroll = this.scroll.bind(this);

    }

    scroll() {

        let scrollTo = document.getElementById(this.props.scrollElementID);
        let rect = scrollTo.getBoundingClientRect();

        window.scrollBy(0,rect.top - 100)

    }

    render() {

      let classExtension = (this.props.inFooter ? ' small' : '') + (this.props.isPixel ? ' pixel' : '');

      return (
          <div onClick={this.scroll} className={"noLinkStyling linkHolder" + classExtension}>

            <div className={'linkText pointer noselect' + classExtension}
                onMouseEnter={this.buttonHoverStart}
                onMouseLeave={this.buttonHoverEnd}>
                  {this.props.children}
            </div>

          </div>
        );
    }
}

export default ScrollLink;