import LinkBar from 'components/Nav/LinkBar';
import Header from 'components/Header/Header';

import TextLink from 'components/Nav/TextLink';

import {COLORS} from 'components/Nav/LinkColors'

function BlogHeader(props) {
    return (
        <Header isPixel={props.isPixel} isSubdomain={true} subdomainText="Blog">
            <LinkBar isPixel={props.isPixel}>
                <TextLink hoverColor={COLORS[0]} path="" isPixel={props.isPixel} inFooter={false}>Home</TextLink>
            </LinkBar>
        </Header>
    );
}


export default BlogHeader;
