import { Switch, Route } from "react-router";

import TopologyHome from "./Home/TopologyHome";

function TopologySubdomain() {
    return (
        <Switch>
          <Route path="/" component={TopologyHome}/>
        </Switch>
    );
};

export default TopologySubdomain;