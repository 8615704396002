import './About.css';

import mascots from 'assets/images/mascots.png';

import MainHeader from 'modules/MainSubdomain/MainHeader';
import MainFooter from 'modules/MainSubdomain/MainFooter';

/* How do we incorporate these into this page
"The greatest scientists are artists as well."   
-Albert Einstein
            
"Only art and science make us suspect the existence of life to a higher level, 
and maybe also instill hope thereof."
- Ludwig van Beethoven
            
"The difference between science and the arts is not that they are different sides 
of the same coin… or even different parts of the same continuum, but rather, they 
are manifestations of the same thing. The arts and sciences are avatars of human 
creativity."
- Mae Jemison

"The arts and sciences are avatars of human creativity."
- Mae Jemison
*/

// TODO: Create new mascot image!

function About() {


    return (
        <div>
            <MainHeader isPixel={false} />
            
            <div className="skinnyContent pageTitleBar" >
                <div className="pageTitle flexResize centeredVertically" style={{flexBasis: 'auto'}}>About Us</div>
                
                <div className="pageTitleText centerTextAlign centeredVertically flexResize" style={{flexBasis: '50%'}}>
                   <img src={mascots} alt="Mascots" className="pixelArt fixedTitleBarImage"></img>
                </div>
            </div>

            <div className="textContent paragraph">


            </div>
            
            <div className="skinnyContent">
                <div className="textContent paragraph">
                
                    Hi, we are Ryan and Gareth, the founders of Bear Science. 
                    Our goal is to teach people about a number of different science and engineering topics while also 
                    learning something ourselves. By combining science with art we aim to develop, 
                    create, and most importantly, educate.
                    <br/><br/>
                    While each of our projects have individual roadmaps, they all have a common theme - 
                    giving back. A significant portion of any revenue recieved by Bear Science will 
                    be donated to various charity organizations. 
                    <br/><br/>
                    We hope you enjoy our work! Feel free to reach out on social media or contact us 
                    directly at info@thebearlab.com
                    
                </div>
            </div>


            <MainFooter/>
            
        </div>
    );
}

export default About;
