import React from "react";

import "./TopologyRoadmap.css"

class TopologyRoadmap extends React.Component {

    render() {
        const title = (            
            <div className="skinnyContent bar flexRow">
                <div className="title2">
                    Roadmap
                </div>
                <div className="flexResize flexCenterVertically">
                    <div style={{ position: 'relative', width: '100%'}} className="centeredVertically">
                        <div className="timelineTop"></div>
                    </div>
                </div>
            </div>
        );

        const showtitle = this.props.showTitle ?? true;


      return (
        <div>

        {showtitle && title}
        
        <div className="skinnyContent">
            <div className="textContent">
                <div className="timeline"> 
                Alpha series- Mint 50 algorithmically generated Topo-Opto-Crypto NFTs. 
                </div> 
                <div className="timeline"> 
                Beta series - Mint 150 algorithmically generated Topo-Opto-Crypto NFTs. 
                15%<sup>*</sup> of all revenue will be donated to education related charities. 
                </div>
                <div className="timeline"> 
                Gamma series - Enable Topo-Opto-Crypto hodlers to generate and mint their own unique 
                pieces. Each NFT from the Alpha series will be allowed to generate 5 unique designs. 
                Each NFT from the Beta series will be allowed to generate 2 uniques designs. 
                20%<sup>*</sup> of all revenue will be donated to education related charities.
                </div>
                <div className="timeline"> 
                Delta series - Develop, create, and explore alternative topology optimization 
                methodologies (eg. 3D designs). Alpha, Beta, and Gamma holders will have similiar 
                creating capabilites after a small (tbd) initial release. 20%<sup>*</sup> of all revenue will be donated 
                to education related charities.  
                </div>
                <div className="timeline"> 
                    25%<sup>*</sup> of all revenue generated going forward will be donated to education related charities.
                </div> 
            </div>
        </div>
        <div className="skinnyContent paragraph">
            <div> <sup>*</sup>If you think these percentages are small you are not alone, we do too! Our goal is 
            to give back and support science and engineering education. We have lots of projects planned and intend 
            to up these percentages as we progress further with our goals.</div>
        </div>
      </div>
      );
    }

}

export default TopologyRoadmap;
