

import BlogFooter from './BlogFooter';
import BlogHeader from './BlogHeader';

function BlogComingSoon() {

    return (
        <div>
            <BlogHeader isPixel={false} />

            <div className="skinnyContent titleBar imageBarBackground right">

                    <div className="aboveOverlay imageBarContent padded">
                        <div className="title ">Blog</div>
                    </div>

            </div>
            
            <div className="skinnyContent gifRow centerText" >

                <div className="topo paragraph centeredHorizontally">
                    <div className="title2">
                        Coming Soon
                    </div>
                </div>
                    
            </div>

            <BlogFooter isPixel={false}/>
            
        </div>
    );
}

export default BlogComingSoon;
