import LinkBar from 'components/Nav/LinkBar';
import Footer from 'components/Footer/Footer';

import ScrollLink from 'components/Nav/ScrollLink';

import {COLORS} from 'components/Nav/LinkColors'

function TopologyFooter(props) {

    const isPixel = props.isPixel || false;

    return (
        <Footer isPixel={isPixel} >
            <LinkBar isPixel={isPixel} inFooter={true}>
                <ScrollLink hoverColor={COLORS[0]} scrollElementID="title" isPixel={props.isPixel} inFooter={true}>Home</ScrollLink>
                <ScrollLink hoverColor={COLORS[1]} scrollElementID="info" isPixel={props.isPixel} inFooter={true}>Description</ScrollLink>
                <ScrollLink hoverColor={COLORS[2]} scrollElementID="mint" isPixel={props.isPixel} inFooter={true}>Mint</ScrollLink>
                <ScrollLink hoverColor={COLORS[3]} scrollElementID="roadmap" isPixel={props.isPixel} inFooter={true}>Roadmap</ScrollLink>
            </LinkBar>
        </Footer>
    );
}


export default TopologyFooter;
