class Bear {

    constructor(name, image, characteristics, isVariant=false, variants=null) {

        this.name = name;
        this.image = image;
        this.characteristics = characteristics;

        this.isVariant = isVariant;
        this.variants = variants;

    }

}
  
export default Bear;