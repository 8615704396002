import ImageScroll from "./ImageScroll/ImageScroll";

import img0 from "./images/0.png"
import img1 from "./images/1.png"
import img2 from "./images/2.png"
import img3 from "./images/3.png"
import img4 from "./images/4.png"
import img5 from "./images/5.png"
import img6 from "./images/6.png"
import img7 from "./images/7.png"
import img8 from "./images/8.png"
import img9 from "./images/9.png"
import img10 from "./images/10.png"
import img11 from "./images/11.png"
import img12 from "./images/12.png"
import img13 from "./images/13.png"
import img14 from "./images/14.png"
import img15 from "./images/15.png"
import img16 from "./images/16.png"
import img17 from "./images/17.png"
import img18 from "./images/18.png"
import img19 from "./images/19.png"
import img20 from "./images/20.png"
import img21 from "./images/21.png"
import img22 from "./images/22.png"
import img23 from "./images/23.png"
import img24 from "./images/24.png"
import img25 from "./images/25.png"
import img26 from "./images/26.png"
import img27 from "./images/27.png"
import img28 from "./images/28.png"
import img29 from "./images/29.png"
import img30 from "./images/30.png"
import img31 from "./images/31.png"
import img32 from "./images/32.png"
import img33 from "./images/33.png"
import img34 from "./images/34.png"
import img35 from "./images/35.png"
import img36 from "./images/36.png"
import img37 from "./images/37.png"
import img38 from "./images/38.png"
import img39 from "./images/39.png"
import img40 from "./images/40.png"
import img41 from "./images/41.png"
import img42 from "./images/42.png"
import img43 from "./images/43.png"
import img44 from "./images/44.png"
import img45 from "./images/45.png"
import img46 from "./images/46.png"
import img47 from "./images/47.png"
import img48 from "./images/48.png"
import img49 from "./images/49.png"

function CandyMachineImageScroll(props) {

    const images = [
        {image: img0, title: "Alpha series - #0"},
        {image: img1, title: "Alpha series - #1"},
        {image: img2, title: "Alpha series - #2"},
        {image: img3, title: "Alpha series - #3"},
        {image: img4, title: "Alpha series - #4"},
        {image: img5, title: "Alpha series - #5"},
        {image: img6, title: "Alpha series - #6"},
        {image: img7, title: "Alpha series - #7"},
        {image: img8, title: "Alpha series - #8"},
        {image: img9, title: "Alpha series - #9"},
        {image: img10, title: "Alpha series - #10"},
        {image: img11, title: "Alpha series - #11"},
        {image: img12, title: "Alpha series - #12"},
        {image: img13, title: "Alpha series - #13"},
        {image: img14, title: "Alpha series - #14"},
        {image: img15, title: "Alpha series - #15"},
        {image: img16, title: "Alpha series - #16"},
        {image: img17, title: "Alpha series - #17"},
        {image: img18, title: "Alpha series - #18"},
        {image: img19, title: "Alpha series - #19"},
        {image: img20, title: "Alpha series - #20"},
        {image: img21, title: "Alpha series - #21"},
        {image: img22, title: "Alpha series - #22"},
        {image: img23, title: "Alpha series - #23"},
        {image: img24, title: "Alpha series - #24"},
        {image: img25, title: "Alpha series - #25"},
        {image: img26, title: "Alpha series - #26"},
        {image: img27, title: "Alpha series - #27"},
        {image: img28, title: "Alpha series - #28"},
        {image: img29, title: "Alpha series - #29"},
        {image: img30, title: "Alpha series - #30"},
        {image: img31, title: "Alpha series - #31"},
        {image: img32, title: "Alpha series - #32"},
        {image: img33, title: "Alpha series - #33"},
        {image: img34, title: "Alpha series - #34"},
        {image: img35, title: "Alpha series - #35"},
        {image: img36, title: "Alpha series - #36"},
        {image: img37, title: "Alpha series - #37"},
        {image: img38, title: "Alpha series - #38"},
        {image: img39, title: "Alpha series - #39"},
        {image: img40, title: "Alpha series - #40"},
        {image: img41, title: "Alpha series - #41"},
        {image: img42, title: "Alpha series - #42"},
        {image: img43, title: "Alpha series - #43"},
        {image: img44, title: "Alpha series - #44"},
        {image: img45, title: "Alpha series - #45"},
        {image: img46, title: "Alpha series - #46"},
        {image: img47, title: "Alpha series - #47"},
        {image: img48, title: "Alpha series - #48"},
        {image: img49, title: "Alpha series - #49"},
    ]

    return (
        <ImageScroll images={images}/>
    );
}


export default CandyMachineImageScroll;
