import React from "react";
import axios from 'axios';

import "./BearInfoModal.css"

import BearCard from 'components/BearCard/BearCard'


class BearInfoModal extends React.Component {

    constructor(props) {

        super(props)

        this.modal = React.createRef();
        this.modalContent = React.createRef();

    }

    openModal() {
        this.modal.current.style.display = '';
        document.body.style.overflowY = 'hidden';
    }

    closeModal(evt) {

        if (evt === undefined || evt.target === this.modal.current) {

            this.modal.current.style.display = 'none';
            document.body.style.overflowY = 'auto';

        }
    }

    componentDidMount() {

        this.closeModal = this.closeModal.bind(this);
        this.modal.current.addEventListener("click", this.closeModal);

    }
    
    componentDidUpdate(oldProps) {

        if (this.props.bear && this.props.bear !== oldProps.bear && this.props.bear.characteristics.wikiTitle) {

            axios.get(`https://en.wikipedia.org/w/api.php?action=query&format=json&origin=*&prop=extracts&titles=${this.props.bear.characteristics.wikiTitle}&formatversion=2&exintro&exlimit=1`)
            .then(res => {

                this.modalContent.current.innerHTML = res.data.query.pages[0].extract + `<a href="https://en.wikipedia.org/wiki/${this.props.bear.characteristics.wikiTitle}" target="_blank"><p>Click here to learn more!</p></a>`;

            })

        }

    }



    render() {

        return (
        <div ref={this.modal} className="blackOverlay" style={{display : 'none'}}>
                <div id="bearInfoModal" className="modal">
                    <div id="bearInfoModalLeft">
                        <BearCard className={"modalItem"} width={"unset"} bear={this.props.bear} showVariants={true} title={true}></BearCard>
                    </div>
                    
                    <div ref={this.modalContent} id="bearInfoModalRight">
                        Loading
                    </div>
                </div>
            </div>
        );

    }


}

export default BearInfoModal;