import LinkBar from 'components/Nav/LinkBar';
import Footer from 'components/Footer/Footer';

import TextLink from 'components/Nav/TextLink';

import {COLORS} from 'components/Nav/LinkColors'

function MainFooter(props) {

    const isPixel = props.isPixel || false;

    return (
        <Footer isPixel={isPixel} >
            <LinkBar isPixel={isPixel} inFooter={true}>
                <TextLink hoverColor={COLORS[0]} path="about" isPixel={isPixel} inFooter={false}>About</TextLink>
            </LinkBar>
        </Footer>
    );
}


export default MainFooter;
