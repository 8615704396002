import LinkBar from 'components/Nav/LinkBar';
import Header from 'components/Header/Header';

import TextLink from 'components/Nav/TextLink';
import Dropdown from 'components/Nav/Dropdown';

import {COLORS, HOVER_COLORS, BACKGROUND_COLORS} from 'components/Nav/LinkColors'

function MainHeader(props) {
    return (
        <Header isPixel={props.isPixel} >
            <LinkBar isPixel={props.isPixel}>
                <TextLink hoverColor={COLORS[0]} path="about" isPixel={props.isPixel} inFooter={false}>About</TextLink>
                <Dropdown id={0} 
                          isPixel={props.isPixel} 
                          mobileDropdowns = { [ {text: 'About', path: 'about' } ] }
                          dropdowns={ [ //{ text: 'Bear Scientists', path: 'scientist' },
                                      { text: 'Topo-Opto-Crypto', path: '', subdomain: 'topology'} ] } 
                          color={COLORS[1]} hoverColor={HOVER_COLORS[1]} backgroundColor={BACKGROUND_COLORS[1]}>
                    Projects
                </Dropdown>
            </LinkBar>
        </Header>
    );
}


export default MainHeader;
