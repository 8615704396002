import galileoImage from 'assets/images/Gal-bearileo-expanded.png'
import bearLabImage from 'assets/images/BearLabTwitter.png'

import MainHeader from 'modules/MainSubdomain/MainHeader'
import MainFooter from 'modules/MainSubdomain/MainFooter'

import Tree from 'components/Tree/D3Tree'

function Home() {

    return (
        <div className="pixelFont">
            <div id="dropdownContentHolder"></div>
            <MainHeader isPixel={true} />

            <div className="grayOverlay skinnyContent right titleBar" >

                <div className="pixelArt bar barBackground right padded"
                     style={{backgroundImage: `url(${bearLabImage})` }}>

                    <div className="aboveOverlay barContent full">
                        <div className="title pixel">Bear Scientists</div>
                        <div className="subtitle pixel" >
                            Working hard in the lab-BEAR-tory
                        </div>
                    </div>

                </div>
            </div>

            <div className="skinnyContent">
                <div className="textContent pixel paragraph">
                    Meet the Bear Scientists, a collection 50px by 50px bears that are devoted to studying the mysteries of the universe.
                    Originally, there were only 10,000 among the rank of the bear scientists, but through the magic of bear labs propogation
                    chamber, any two bears can combine their genetics and chracteristics to create an entirely new bear scientist. Each one
                    of these individuals has their proof of ownership stored on the Ethereum blockchain allowing for their owners to openly trade
                    them.
                </div>
            </div>

            <div className="pixelArt imageBar wideBackgroundImage" style={{ backgroundColor: "#07254a", backgroundImage: `url(${galileoImage})`}}>
                
            </div>

            <div className="skinnyContent">
                <div className="title pixel" style={{ marginTop: 20 }}>More Stuff Here</div>
                <div className="textContent pixel paragraph">
                    Meet the bear scientists, a collection 50px by 50px bears that are devoted to studying the mysteries of the universe.
                    Originally, there were only 10,000 among the rank of the bear scientists, but through the magic of bear labs propogation
                    chamber, any two bears can combine their genetics and chracteristics to create an entirely new bear scientist. Each one
                    of these individuals has their proof of ownership stored on the Ethereum blockchain allowing for their owners to openly trade
                    them.
                </div>
            </div>

            <div style={{width: '100%', height: '1000px'}}>
                <Tree/>
            </div>

            <MainFooter isPixel={true} />
            
        </div>
    );
}

export default Home;
