import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import BlogSubdomain from "modules/BlogSubdomain/BlogSubdomain";

import DefaultSubdomain from "../modules/MainSubdomain/DefaultSubdomain";
import TopologySubdomain from "../modules/TopologySubdomain/TopologySubdomain";
 
function getSubdomain(hostname) {
    // eslint-disable-next-line

    var parts = hostname.split('.');
    var subdomain = parts.shift();

    return subdomain;
}

function SubdomainRouter() {
    
    let subdomainName = getSubdomain(window.location.hostname);
    let subdomainCotent = <DefaultSubdomain/>;

    if (subdomainName === "topology") {
        subdomainCotent = <TopologySubdomain/>;
    } else if (subdomainName === "blog") {
        subdomainCotent = <BlogSubdomain/>;
    }

    return (
        <ScrollToTop>
            { subdomainCotent }
        </ScrollToTop>
    );
}

export default SubdomainRouter;