import React from "react";

import SocialMediaLink from "./SocialMediaLink";

import twitterLogo from 'assets/logos/twitter_logo.png'
import redditLogo from 'assets/logos/reddit_logo.png'

import './LinkBar.css'

const TWITTER_COLOR = 'rgb(29, 155, 240)';
const REDDIT_COLOR = 'rgb(255, 69, 0)';


class LinkBar extends React.Component {

    constructor(props) {

        super(props)

        this.isPixel = props.isPixel || false;

    }

    render() {
        return (
            <div className="flexRow linkBar">
                {this.props.children}
                <SocialMediaLink hoverBackgroundColor={TWITTER_COLOR} img={twitterLogo} link="https://twitter.com/BearScienceLab" isPixel={this.isPixel} inFooter={this.inFooter}></SocialMediaLink>
                <SocialMediaLink hoverBackgroundColor={REDDIT_COLOR} img={redditLogo} link="https://www.reddit.com/r/BearScienceNFT/" isPixel={this.isPixel} inFooter={this.inFooter}></SocialMediaLink>
            </div>
        );
    }
}

export default LinkBar;